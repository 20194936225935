import React, { useState,useEffect } from 'react';
import Navbar from '../Components/Navbar';
import './Home.css';
import { Link } from 'react-router-dom';


const Portfolio = () => {
//   const [isLoading, setIsLoading] = useState(true);

//   // Simulating a delay to showcase the loading state
//   setTimeout(() => {
//     setIsLoading(false);
//   }, 0);
const [isMobile, setIsMobile] = useState(false);

useEffect(() => {
    // Function to check if the user is on a mobile device
    const checkIsMobile = () => {
      const mobileMediaQuery = window.matchMedia('(max-width: 767px)'); // Adjust the breakpoint to your preference
      setIsMobile(mobileMediaQuery.matches);
    };

    // Initial check
    checkIsMobile();

    // Add event listener for changes in screen size
    window.addEventListener('resize', checkIsMobile);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  return (
    <div style={{ marginLeft:'5%', marginRight:isMobile? '10%':'40%', backgroundColor:'black', height:'105vh', marginTop:'-5vh'}}>
      <h1 style={{textAlign:'left'}}></h1>
      <div style={{height:'5vh'}}></div>
      <Link to="/" style={{ textDecoration: 'none' }}>
        <h3>back</h3>
      </Link>
  
      <h1 style={{textAlign:'left'}}>our portfolio</h1>

      <div style={{textAlign:'left', background:'#333300' , border: '5px solid #663300', padding:'20px'}}> 
       
        <p>
       With US$1.4B of assets under management, mox capital's investments span across diverse themes. </p>

     </div>
  
      <div style={{display: 'flex', flexWrap: 'wrap', gap: isMobile ? '3%' : '4%', marginTop: isMobile ? '3%' : '5vh' }}> 
        {/* Buttons for the first row */}
        <button style={{ flex: 1, textAlign: 'left', background: '#333300', border: '5px solid #663300', padding: '10px', alignItems: 'center', marginBottom: '10px' }}> 
          <h3>[NFTs]</h3>
        </button>
  
        <button style={{ flex: 1, textAlign: 'left', background: '#333300', border: '5px solid #663300', padding: '10px', alignItems: 'center', marginBottom: '10px' }}> 
          <h3>[Tokens]</h3>
        </button>
  
        <button style={{ flex: 1, textAlign: 'left', background: '#333300', border: '5px solid #663300', padding: '10px', alignItems: 'center', marginBottom: '10px' }}> 
          <h3>[Incubation]</h3>
        </button>
  
        {/* Buttons for the second row */}

        <button style={{ flex: 1, textAlign: 'left', background: '#333300', border: '5px solid #663300', padding: '10px', alignItems: 'center', marginBottom: '10px' }}> 
          <h3>[Digital Banks]</h3>
        </button>
  
        <button style={{ flex: 1, textAlign: 'left', background: '#333300', border: '5px solid #663300', padding: '10px', alignItems: 'center', marginBottom: '10px' }}> 
          <h3>[Protocols]</h3>
        </button>
  
        <button style={{ flex: 1, textAlign: 'left', background: '#333300', border: '5px solid #663300', padding: '10px', alignItems: 'center', marginBottom: '10px' }}> 
          <h3>[Collectibles]</h3>
        </button>
      </div>
    </div>
  );
  
  
};

export default Portfolio;
